import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import parkingStyles from "../styles/parking.module.scss"

import { Container, Grid } from "../components/grid/grid"
import { BtnContainedPrimary } from "../components/button/button"

import ImageParkinglock from "../components/images/imageParkinglock"
import ImageParkinghide from "../components/images/imageParkinghide"
import ImageParkingparquick from "../components/images/imageParkingparquick"

import IconMagnet from "../static/icon-magnet.svg"
import IconNear from "../static/icon-near.svg"
import IconUnlock from "../static/icon-unlock.svg"
import IconParking from "../static/icon-parking.svg"
import IconNotlowcost from "../static/icon-notlowcost.svg"
import IconCoins from "../static/icon-coins.svg"

const Iconcolumn = props =>(
  <div className={parkingStyles.iconcolumn}>
      <div>
          {props.children}
      </div>
      <div>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
      </div>
  </div>
)


export default class ParkingPage extends React.Component {
  state = {
    parking: "",
    contact: "",
    email: "",
    phone: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <Layout>
      <SEO 
      title="Parquick para aparcamientos"
      description="¿Eres propietario de un parking en Barcelona? Únete a Parquick, el servicio que te ayuda a conseguir más coches. Sin reservas. Sin comisiones."
      />

        <div className={parkingStyles.header + " bgblue"}>
          <div className={parkingStyles.ilustration}>
            <ImageParkinglock alt="parking vacio"/>
          </div>
          <h1>
            ¿Piensas en cómo recuperar tus ingresos caídos debido al Covid lo antes posible?
          </h1>
          <p class="center body-large">Tienes a los abonados, pero te <span class="underline">bloquean las plazas</span>.</p>
          <p class="center body-large">Las reservas online están paradas, y te obligan a competir con <span class="underline">tarifas low cost</span>.</p>
          <p class="center body-large">La rotación es EL NEGOCIO. Pero no sabes <span class="underline">como atraerlos</span> en la situación actual…</p>
        </div>

        <div className={parkingStyles.ilustration}>
            <ImageParkinghide alt="parking escondido"/>
        </div>

        <div className={parkingStyles.solutionWrap}>
          <p className={parkingStyles.titlenote + " margintop1"}>
            ·  Y por si no lo sabías  ·</p>
          <h2 class="center">
            Cada día miles de conductores...
          </h2>
          <p class="center">…quieren aparcar al llegar al destino, pero no es nada fácil. Pierden tiempo dando vueltas. Malgastan combustible, aparcan lejos, y acaban cabreados.</p>
          <div className={parkingStyles.verticalline}></div>
          <h2 class="center blue">
            Parquick te trae esa rotación a tu parking.
          </h2>
          <h2 class="center">+100.000 conductores</h2>
          <p class="center">usarán Parquick para aparcar al instante.</p>
          <div className={parkingStyles.ilustration}>
            <ImageParkingparquick alt="parking escondido"/>
          </div>
              <p class="body-large center">Uniéndote a Parquick, abres la puerta a atraer exclusivamente vehículos de rotación. La mayor fuente de ingresos de un aparcamiento.</p>

        </div>

        <div className={parkingStyles.brick + " bgdark + margintop3"}>
          <Container>
            <Grid>
              <div class="l-start-1 l-end-4 m-start-1 m-end-3">
                <Iconcolumn
                  title="Te atraemos a la rotación."
                  description="El único servicio enfocado a los conductores que buscan aparcar al momento en su destino."
                >
                  <img src={IconMagnet} alt="Te atraemos a la rotación."/>
                </Iconcolumn>
              </div>

              <div class="l-start-5 l-end-8 m-start-3 m-end-5">
                <Iconcolumn
                  title="Captamos los coches cercanos"
                  description="a tu parking que necesitan aparcar. Y los guiamos hasta la tu vía de entrada."
                >
                  <img src={IconNear} alt="Captamos los coches cercanos"/>
                </Iconcolumn>
              </div>

              <div class="l-start-9 l-end-12 m-start-5 m-end-7">
                <Iconcolumn
                  title="Sin bloquear tus plazas."
                  description="Parquick ofrece tus plazas libres a los conductores. Sin bloquearlas. Sin perder ingresos."
                >
                  <img src={IconUnlock} alt="Sin bloquear tus plazas."/>
                </Iconcolumn>
              </div>

              <div class="l-start-1 l-end-4 m-start-1 m-end-3">
                <Iconcolumn
                  title="Sin competir con otros parkings."
                  description="Los conductores te elegirán principalmente por estar cerca del destino al que se dirigen."
                >
                  <img src={IconParking} alt="Sin competir con otros parkings."/>
                </Iconcolumn>
              </div>

              <div class="l-start-5 l-end-8 m-start-3 m-end-5">
                <Iconcolumn
                  title="No peleas con precios low cost."
                  description="Tú marcas los precios. Parquick no te obliga a establecer tarifas o paquetes especiales."
                >
                  <img src={IconNotlowcost} alt="No peleas con precios low cost."/>
                </Iconcolumn>
              </div>

              <div class="l-start-9 l-end-12 m-start-5 m-end-7">
                <Iconcolumn
                  title="Tú gestionas tus propios ingresos."
                  description="El conductor pagará la estancia en tu aparcamiento, al igual que los demás clientes."
                >
                  <img src={IconCoins} alt="Tú gestionas tus ingresos."/>
                </Iconcolumn>
              </div>
            </Grid>
          </Container>
        </div>

        <Container>
          <Grid>
            <div class="l-start-2 l-end-12 m-start-2 m-end-6">
              <h2 class="center margintop3 blue">Adelanta la recuperación de tus ingresos, nuestros conductores esperan tu parking.</h2>
            </div>
            
            <div class="l-start-2 l-end-5 m-start-1 m-end-3">
              <p class="center body-ultralarge blue margintop0-5">Sin comisiones sobre ventas.</p>
            </div>

            <div class="l-start-6 l-end-8 m-start-3 m-end-5">
              <p class="center body-ultralarge blue margintop0-5">Sin cuota de alta.</p>
            </div>

            <div class="l-start-9 l-end-12 m-start-5 m-end-7">
              <p class="center body-ultralarge blue margintop0-5">Sin cuota de mantenimiento.</p>
            </div>

            <div class="margintop3 l-start-4 l-end-10 m-start-2 m-end-6">
              <p class="center">Déjanos tus datos para que ofrecerte más información:</p>

              <form class="margintop0-5" method="post" action="https://formspree.io/xlegqrry">
                
                <Grid>
                  <div class="margintop0-5 l-start-1 l-end-7 m-start-1 m-end-4">
                    <label for="email">
                    Email <span class="required">*</span>
                    
                      <input required
                          id="email"
                          type="email"
                          name="email"
                          placeholder="nombre@email.com"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                  <div class="margintop0-5 l-start-7 l-end-13 m-start-4 m-end-7">
                    <label for="phone">
                    Teléfono <span class="required">*</span>
                    
                      <input required
                        id="phone"
                        type="tel"
                        name="phone"
                        placeholder="000 000 000"
                        value={this.state.time}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                  <div class="margintop0-5 l-start-1 l-end-7 m-start-1 m-end-4">
                    <label for="contact">
                    Persona de contacto <span class="required">*</span>
                    
                      <input required
                        id="contact"
                        type="text"
                        name="contact"
                        placeholder="Nombre Apellido"
                        value={this.state.time}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                  <div class="margintop0-5 l-start-7 l-end-13 m-start-4 m-end-7">
                    <label for="parking">
                      Nombre del parking <span class="required">*</span>
                    
                      <input required
                        id="parking"
                        type="tel"
                        name="parking"
                        placeholder="Parking"
                        value={this.state.time}
                        onChange={this.handleInputChange}
                      />
                    </label>
                  </div>

                </Grid>

                <div class="margintop0-5 inputWrap">
                  <input class="checkbox" required type="checkbox" id="accept" name="accept" value="Acepto la política de privacidad"/>
                  <label for="accept">Acepto la política de privacidad.</label>
                </div>
                
                  
                <div class="margintop1">
                  <BtnContainedPrimary>Enviar</BtnContainedPrimary>
                </div>
              </form>

            </div>
          </Grid>
        </Container>
      </Layout>
    )
  }
}